<template id="main">
  <div class="video-container" id="mainVideo">
    <!-- <video id="mainVideo" autoplay loop muted>
      <source src="@/assets/videos/promoVideo.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video> -->
    <div
      class="wistia_responsive_padding"
      style="
        padding: 10% 0 0 0;
        position: absolute;

        /* height: 80%;
        width: 100%; */
      "
    >
      <div
        class="wistia_responsive_wrapper"
        style="left: 0; position: relative; top: 0; width: 100vw"
      >
        <div
          class="wistia_embed wistia_async_8vpglzn4hr autoPlay=true muted=true loop=true"
          style="position: relative; width: 100vw"
          id="wPlayer"
        >
          &nbsp;
        </div>
      </div>
    </div>
    <div id="videoOverlay" class="overlay">
      <!-- <mainHeader id="mainHeader" /> -->
      <div id="test"></div>
      <div id="mainVideoSlogan">Your Safety program in one place</div>
      <p id="sloganParagraph">Safe Eye Safety Management Software</p>

      <div class="flexCol">
        <button class="vidBtn" id="playVidBtn" @click="playVideo">
          Play Video
        </button>

        <!-- <button id="bookDemoBtn">Book demo</button> -->
        <a
          href=""
          id="bookDemoBtn"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/safe-eye/free-consultation'});return false;"
          >Schedule demo</a
        >
      </div>
    </div>
  </div>
  <div id="spacer"></div>
</template>

<script>
  // import mainHeader from "@/components/headersAndFooters/mainHeader.vue";

  // import { onMounted } from "vue";
  export default {
    name: "mainPageVideo",
    components: {
      // mainHeader,
    },

    setup() {
      // var player = new WestiaPlayer("westia-player", {
      //   videoId: "your-video-id",
      //   autoplay: false,
      //   controls: true,
      //   width: 640,
      //   height: 360,
      // });

      // window._wq = window._wq || [];
      // _wq.push({
      //   id: "abcde12345",
      //   onReady: function (video) {
      //     console.log("I got a handle to the video!", video);
      //   },
      // });

      function playVideo() {
        // player.seekTo(0);
        // player.muted(false);
        // video.play();
      }

      return { playVideo };
    },
  };
</script>

<style scoped>
  #main {
    width: 100vw;
    padding: 0rem;
    display: flex;
    border: 3px solid purple;
  }
  #stopVidBtn {
    display: none;
  }
  .vidBtn {
    margin-top: 1em;
    margin-bottom: 0.5em;

    /* margin-left: 35%; */
    font-size: 1.5em;
    font-weight: 600;
    font-family: vf, Arial, sans-serif;
    color: rgb(249, 246, 246);
    text-shadow: 0px 2px 2px #5a5959;
    background-color: var(--primary-color);
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
    /* border: 2px solid rgba(249, 245, 245, 0.2); */
    width: 15rem;
    /* border: 2px solid rgba(231, 9, 9, 0.987);
      z-index: 100; */
    text-decoration: none;
  }

  #playVidBtn,
  #bookDemoBtn {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  #bookDemoBtn {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-size: 1.5em;
    font-weight: 600;
    font-family: vf, Arial, sans-serif;
    color: rgb(249, 246, 246);
    /* text-shadow: 0px 2px 2px #5a5959; */
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgba(249, 245, 245, 0.2);
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
    width: 20rem;
    text-decoration: none;

    background-color: rgba(0, 0, 0, 0.3);
  }

  #mainVideoSlogan {
    font-size: 4em;
    font-weight: 800;
    margin-bottom: 0.5em;
    font-family: vf, Arial, sans-serif;
    color: rgb(249, 246, 246);
    text-shadow: 0px 2px 2px #5a5959;
  }

  .video-container {
    /* border: 11px solid green; */
    /* position: absolute; */
    position: relative;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    width: 100vw;
    height: auto;
    /* padding-bottom: 1rem; */
    /* 16:9 aspect ratio */
    /* border: 1px solid red; */
    /* max-height: auto; */
    /* margin: 4rem; */
    margin-bottom: 0rem;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    /* margin-top: 2rem; */
  }

  #mainVideo {
    position: relative;
    top: 0;
    left: 0;

    /* background-color: rgba(0, 0, 0, 1);  */
    /* Set alpha to 50% */
    filter: brightness(1); /* Darken the video by 50% */
    width: 100vw;

    /* border: 1px solid rgb(28, 206, 19); */
    /* min-height: 40vh; */
    /* object-fit: scale-down; */
    /* z-index: 4; */
    align-items: center;
    /* border: 5px solid yellow; */
  }

  .overlay {
    /* border: 2px solid red; */
    position: absolute;

    /* align-self: center; */
    /* 
    top: 0;
    left: 0; */

    left: 0rem;
    width: 100%;
    /* padding: 1rem; */
    /* min-height: 40vh; */
    /*Should be the same as .video-container height as they are overlayed*/
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    justify-self: center; */

    /* align-items: space-in-between; */
    color: white;
    text-align: center;
    /* border: 4px solid red; */
  }

  #sloganParagraph {
    font-size: 2em;
    font-weight: 600;
    font-family: vf, Arial, sans-serif;
    color: rgb(249, 246, 246);
    text-shadow: 0px 2px 2px #5a5959;
  }

  #videoSeLogo {
    /* align to top left */
    position: absolute;
    top: 0;
    left: 0;
    /* align-self: flex-start; */
  }

  .video-container video {
    position: relative;
    top: 4rem;
    left: 0rem;
    width: 100vw;
    /* height: auto; */
    /* border: 2px solid red;
      margin:5rem; */
    object-fit: cover;
  }

  .overlay h1 {
    font-size: 3em;
    margin-bottom: 0.5em;
  }

  .overlay p {
    font-size: 1.5em;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #mainContainer {
    /* background-color: rgba(255, 255, 255, 0.25); */
    width: 100vw;
    height: 4rem;

    width: 100rem;
    height: 4rem;
    border: 2px solid rgb(0, 255, 0);
  }

  #mainHeader {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 4rem;
    /* border: 2px solid rgb(21, 0, 255); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: rgba(255, 255, 255, 0.25); */
    padding-right: 15%;
    position: sticky;
    top: 0rem;
    /* z-index: 0; */
    left: 0rem;
  }

  #spacer::after {
    content: " ";
    position: relative;
    display: block;
    height: 0rem;
  }

  .wistia_embed {
    width: 100%;
    height: 100%;

    /* border: 2px solid rgb(255, 0, 0); */
  }

  /* #mainHeader:hover {
      background-color: rgba(255, 255, 255, 0.5);
    } */

  /*    media queries
  */
  /* Extra Small Devices (portrait phones, less than 576px) */
  @media only screen and (max-width: 575.98px) {
    /* styles for extra small devices */

    #mainVideo {
      /* margin: 2.5rem; */
      width: 100vw;
      /* border: 2px solid red; */
    }
    .video-container {
      /* position: relative; */

      display: flex;
      /* flex-direction: column; */
      justify-content: center;
      align-items: center; /* Center content vertically */

      width: 100%;
      width: 100vw;
      padding-bottom: 1rem;
      /* 16:9 aspect ratio */
      /* border: 1px solid red; */
      /* max-height: auto; */
      /* margin: 4rem; */
      margin-bottom: 0rem;

      /* margin-top: 10rem; */
    }

    .overlay {
      position: relative;
      text-shadow: 0px 2px 2px #5a5959;

      left: 0rem;
      width: 100%;
      padding: 0rem;
      /* min-height: 40vh; */
      /*Should be the same as .video-container height as they are overlayed*/
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      justify-self: center;
      /* align-items: space-in-between; */
      color: white;
      text-align: center;
      font-size: 1rem;
      /* border: 4px solid red; */

      /* background-color: black; */
    }

    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      border-radius: 0.5rem;
      width: 20rem;
      text-decoration: none;
      white-space: nowrap;

      background-color: rgba(0, 0, 0, 0.3);
      margin-left: 2rem;
    }

    .vidBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;

      /* margin-left: 35%; */
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      text-shadow: 0px 2px 2px #5a5959;
      background-color: var(--primary-color);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      border-radius: 0.5rem;
      /* border: 2px solid rgba(249, 245, 245, 0.2); */
      width: 7rem;
      /* border: 2px solid rgba(231, 9, 9, 0.987);
      z-index: 100; */
      text-decoration: none;
    }

    #mainVideoSlogan {
      font-size: 2rem;
    }
    #sloganParagraph {
      font-size: 1rem;
      margin: 0.5rem;
    }
    .flexCol {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 350px) {
    #videoOverlay {
      margin-top: 25vw;
    }
  }

  @media only screen and (max-width: 450px) {
    #videoOverlay {
      margin-top: 30vw;
    }

    .overlay {
      /* margin-bottom: 4rem; */
      bottom: 0vh;
    }

    #mainVideoSlogan,
    #sloganParagraph {
      color: white;
      text-shadow: none;
      font-family: vf;
    }
    .overlay {
      /* position: relative; */
      /* margin-top: -20rem;
      top: -10vh; */
      bottom: 0vh;
    }
  }

  @media only screen and (max-width: 576px) {
    #mainVideoSlogan {
      font-size: 2rem;
    }

    #mainVideoSlogan {
      font-size: 2rem;
      /* color: red; */
      line-height: 2rem;
      font-weight: 600;
      z-index: 1;
    }

    #sloganParagraph {
      font-size: 1rem;
      margin: 0rem;
      z-index: 1;
    }

    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 0.3rem 0.3rem 0.3rem 0.3rem;
      border-radius: 0.5rem;
      width: 12rem;
      text-decoration: none;
      white-space: nowrap;

      background-color: rgba(0, 0, 0, 0.3);
      margin-left: 2rem;
    }

    .vidBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;

      /* margin-left: 35%; */
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      text-shadow: 0px 2px 2px #5a5959;
      background-color: var(--primary-color);
      padding: 0.3rem 0.3rem 0.3rem 0.3rem;
      border-radius: 0.5rem;
      /* border: 2px solid rgba(249, 245, 245, 0.2); */
      width: 10rem;
      /* border: 2px solid rgba(231, 9, 9, 0.987);
      z-index: 100; */
      text-decoration: none;
      margin-right: 1rem;
    }

    .overlay {
      bottom: 0vh;
    }

    .flexCol {
      flex-direction: row;
    }
  }

  /* Small Devices (landscape phones, 576px and up) */
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    #mainVideoSlogan {
      font-size: 2rem;
    }
    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      width: 12rem;
      text-decoration: none;
      white-space: nowrap;

      background-color: rgba(0, 0, 0, 0.3);
      margin-left: 2rem;
    }

    .vidBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;

      /* margin-left: 35%; */
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      text-shadow: 0px 2px 2px #5a5959;
      background-color: var(--primary-color);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      /* border: 2px solid rgba(249, 245, 245, 0.2); */
      width: 12rem;
      /* border: 2px solid rgba(231, 9, 9, 0.987);
      z-index: 100; */
      text-decoration: none;
      margin-left: 2rem;
    }

    #mainVideo {
      /* margin: -4rem; */
      bottom: 0vh;
    }

    .overlay {
      /* top: 10rem; */
      bottom: 0vh;
    }

    .flexCol {
      flex-direction: row;
    }
    /* styles for small devices */
  }
  .flexCol {
    flex-direction: row;
  }

  /* Medium Devices (tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    /* styles for medium devices */

    .overlay {
      bottom: 0vh;
    }

    .flexCol {
      flex-direction: row;
    }
    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      width: 10rem;
      text-decoration: none;
      white-space: nowrap;

      background-color: rgba(0, 0, 0, 0.3);
      /* border: 2px solid red; */
      margin-left: 2rem;
    }

    .vidBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;

      /* margin-left: 35%; */
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      text-shadow: 0px 2px 2px #5a5959;
      background-color: var(--primary-color);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      /* border: 2px solid rgba(249, 245, 245, 0.2); */
      width: 10rem;
      /* border: 2px solid rgba(231, 9, 9, 0.987);
      z-index: 100; */
      text-decoration: none;
    }

    #mainVideoSlogan {
      font-size: 3rem;
    }

    #mainVideo {
      bottom: 0vh;
    }
  }

  /* Large Devices (desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    /* styles for large devices */

    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      width: 10rem;
      text-decoration: none;
      white-space: nowrap;

      background-color: rgba(0, 0, 0, 0.3);
      margin-left: 1rem;
    }
    .flexCol {
      flex-direction: row;
    }

    .vidBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;

      /* margin-left: 35%; */
      font-size: 1em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      text-shadow: 0px 2px 2px #5a5959;
      background-color: var(--primary-color);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      /* border: 2px solid rgba(249, 245, 245, 0.2); */
      width: 10rem;
      /* border: 2px solid rgba(231, 9, 9, 0.987); */
      /* z-index: 100; */
      text-decoration: none;

      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      box-shadow: rgba(236, 5, 5, 0.16) 0px 1px 4px,
        rgb(51, 51, 51) 0px 0px 0px 3px;
    }

    .overlay {
      bottom: 0vh;
    }

    #mainVideo {
      /* margin: -14rem; */
    }
  }

  /* Extra Large Devices (large desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .overlay {
      bottom: 0vh;
    }

    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1.5em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      width: 15rem;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.3);
      margin-left: 2rem;
    }

    #mainVideo {
      /* margin: -16rem; */
    }
    /* styles for extra large devices */
  }

  @media only screen and (min-width: 1500px) {
    #spacer::after {
      /* height: 40rem; */
    }
    .overlay {
      /* top: 68vh; */
    }

    #bookDemoBtn {
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-size: 1.5em;
      font-weight: 600;
      font-family: vf, Arial, sans-serif;
      color: rgb(249, 246, 246);
      /* text-shadow: 0px 2px 2px #5a5959; */
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid rgba(249, 245, 245, 0.2);
      padding: 1rem 1rem 1rem 1rem;
      border-radius: 0.5rem;
      width: 15rem;
      text-decoration: none;
      /* border: 2px solid red; */

      background-color: rgba(0, 0, 0, 0.3);
      margin-left: 2rem;
    }

    #mainVideo {
      /* margin: -18rem; */
    }

    /* styles for extra large devices */
  }
</style>
