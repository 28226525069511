<template>
  <div class="container">
    <div id="topHeader">
      <div id="topHeaderPhoneNumber">
        <a class="topHeaderAnchor">
          <img
            id="phoneImageSVG"
            src="@/assets/styles/characters/phone.svg"
            alt=""
          />
          <a href="tel:4034345865" id="phoneNumber"> 403-434-5865</a>
        </a>
      </div>
      <div class="topHeader__menu">
        <ul class="columnContainer">
          <li><a href="contactPage" class="topHeaderAnchor">Contact</a></li>
          <!-- <li><a href="#" class="topHeaderAnchor">Support</a></li> -->
          <li class="columnContainer">
            <a class="topHeaderAnchor" href="https://safe-eye.app/"
              >Login
              <img
                id="loginImage"
                src="@/assets/styles/characters/loginLogo/icons8-login-ios-16-96.png"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <mainHeader id="mainHeader" />
  </div>
</template>

<script>
  import mainHeader from "@/components/headersAndFooters/mainHeader.vue";

  export default {
    name: "topHeader",
    props: {
      msg: String,
    },
    components: {
      mainHeader,
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;

    position: fixed;
    top: 0rem;
    z-index: 1;
    left: 0rem;
  }
  #topHeader {
    display: flex;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    justify-content: space-between;
    background-color: black;
    align-items: center;
    padding-left: 2rem;
    padding-right: 3rem;
    color: var(--se-light);
    font-weight: 500;
    font-size: 0.3rem;
    max-height: 0.08rem;

    /* height: 8rem; */
    width: 100%;

    padding-left: 0.1rem;
    padding-right: 0.1rem;

    /* position: fixed;
    top: 0rem;
    z-index: 1;
    left: 0rem; */
    /* min-width: 100vw; */
    /* border: 2rem solid red; */
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  #topHeaderPhoneNumber {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0rem;
    padding: 0rem;
    max-height: 1rem;
    margin-left: 1rem;
  }

  #phoneImageSVG {
    z-index: 1;
    width: 1.25rem;
    /* height: 1.5rem; */
    margin: 0 5px;
    -webkit-filter: invert(80%); /* safari 6.0 - 9.0 */
    filter: invert(83%);
  }

  #topHeaderPhoneNumber {
    /* Set initial size of the div */
    /* width: auto; */
    /* height: 100px; */
    /* Set the animation properties */
    animation-name: scale;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    /* Set the animation to run forwards and backwards */
    animation-direction: alternate;
    /* Pause the animation by default */
    animation-play-state: paused;

    display: flex;
    justify-content: flex-start;
    /* border: 1px solid red; */
  }

  /* Define the animation */
  @keyframes scale {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }

  /* Add hover effect to the div */
  #topHeaderPhoneNumber:hover {
    /* Start the animation on hover */
    animation-play-state: running;
  }

  .topHeader__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0rem;
    padding: 0rem;
    /* max-height: 1rem; */
    /* border: 1px solid red; */
    /* min-width: auto; */
  }

  #loginImage {
    width: 1.25rem;
    margin-left: 0.5rem;
  }

  .columnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0rem;
    padding: 0rem;
    max-height: 1rem;
  }

  .topHeaderContainer {
  }
  .topHeaderAnchor,
  #loginImage {
    color: var(--se-light);
    display: flex;
    align-items: center;

    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-decoration: none;
    font-size: 1rem;

    margin: 0.125rem;

    transition: all 0.3s ease-in-out;
  }

  .topHeaderAnchor:hover,
  #loginImage:hover {
    color: var(--se-dark);
    margin: 0.25rem;
    font-size: 1.1rem;
  }

  #mainHeader {
    min-width: 100vw;
    /* min-height: 8rem; */
    /* border: 1rem solid red; */
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid red; */
    justify-self: flex-end;

    background-color: rgba(11, 11, 11, 0.65);
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  #phoneNumber {
    font-size: 1rem;
    text-decoration: none;
    color: var(--se-light);
  }

  /* Define the filter */
  /* Extra Small Devices (portrait phones, less than 576px) */
  @media only screen and (max-width: 575.98px) {
    #topHeader {
      /* margin: ; */
    }

    /* #phoneNumber {
      display: none;
    } */
    /* a {
    color: red;
  } */
    /* styles for extra small devices */
  }

  /* Small Devices (landscape phones, 576px and up) */
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    /* styles for small devices */
    /* #phoneNumber {
      display: none;
    } */
  }

  /* Medium Devices (tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    /* styles for medium devices */
  }

  /* Large Devices (desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    /* styles for large devices */
  }

  /* Extra Large Devices (large desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* styles for extra large devices */
  }
</style>
