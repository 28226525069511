<template>
  <topHeader />

  <router-view> </router-view>
  <!-- <bottomFooter /> -->
</template>

<script>
  import topHeader from "@/components/headersAndFooters/topHeader.vue";
  // import "@/assets/styles/variables.css";
  // import bottomFooter from "./components/headersAndFooters/bottomFooter.vue";
  export default {
    name: "App",
    components: {
      topHeader,
      // bottomFooter,
    },

    setup() {
      return {};
    },
  };
</script>

<style scoped>
  /* topHeader {
    position: fixed;
    top: 0rem;
    z-index: 5;
    left: 0rem;
    border: 20px solid red;
  } */

  h1 {
    color: red;
    z-index: 999;
  }

  /* bottomFooter {
    margin-top: 1rem;
    border: 2px solid green;
  } */
</style>
