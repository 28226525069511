<template>
  <a class="container" href="/">
    <img
      id="loginImage"
      src="@/assets/images/icons/favicon-512x512.png"
      alt=""
    />

    <!-- <h2 href="" id="logoText"></h2> -->
    <br />
    <div id="seText">Safe Eye</div>
  </a>
</template>

<script>
  export default {
    name: "seLogo",
  };
</script>

<style scoped>
  #seText {
    font-size: 300%;
    font-weight: 500;
    color: white;
    padding-left: 0.8rem;
    white-space: nowrap; /* Add this line */
    text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  #loginImage {
    /* height: 80%; */

    /* height: 3.2rem; */

    margin: 0.1rem;
    /* padding: 1vw; */
    max-height: 4rem;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin-left: 3rem;
  }
  #logoText {
    font-size: 2rem;
    font-weight: 500;
    color: black;
    margin: 0rem;
    padding-left: 0.8rem;
    /* max-height: 4rem; */
    text-decoration: none;
    font-family: vf;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem;
    padding: 0rem;
    /* max-height: 4rem; */
    width: 30rem;
    text-decoration: none;
  }

  h1 {
    color: #f5eeee;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    font-weight: 500;
  }

  @media only screen and (max-width: 350px) {
  }
  @media only screen and (max-width: 450px) {
  }
  @media only screen and (max-width: 576px) {
    #loginImage {
      width: 2rem;
      margin-left: 0rem;
    }
    #seText {
      font-size: 150%;
      padding-left: 0.25rem;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    #loginImage {
      width: 2.5rem;
    }
    #seText {
      font-size: 200%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    #loginImage {
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  }
  @media only screen and (min-width: 1200px) {
  }
  @media only screen and (min-width: 1500px) {
  }
</style>
