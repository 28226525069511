<template>
  <!-- <topHeader /> -->

  <div class="page" id="mainPage">
    <mainPageVideo id="mainPageVideo" />
    <section class="sideBySide">
      <div class="colContainer">
        <div class="justifyLeft">
          <h1 class="floatingHeader">Organized. Easier. Better. Faster</h1>
          <h1 class="largeHeader">
            All your forms, available to all workers, all the time.
          </h1>
          <h1 class="largeHeader"></h1>
          <h7 class="largeHeaderParagraph">
            Safety forms often are disorganized, missing, damaged and
            incomplete.Now you can insure they are permanently stored and easy
            to find.
          </h7>
        </div>
      </div>
      <div class="colContainer">
        <video
          id="mainVideo"
          autoplay
          loop
          muted
          poster="@/assets/images/appPics/formsImage.webp"
          loading="lazy"
          preload="none"
        >
          <source src="@/assets/videos/formVideo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>

    <section class="sideBySide">
      <div class="colContainer">
        <img
          class="appImage"
          src="@/assets/images/appPics/certImage.webp"
          alt="Safe Eye Logo"
        />
      </div>
      <div class="colContainer">
        <div class="justifyLeft">
          <h1 class="floatingHeader">Organized. Easier. Better. Faster</h1>
          <h1 class="largeHeader">Manage certifications</h1>
          <!-- <h1 class="largeHeader">with Safe Eye</h1> -->
          <h7 class="largeHeaderParagraph">
            Track worker certifications in one place and never miss a renewal or
            missing certification again.
          </h7>
        </div>
      </div>
    </section>

    <section class="sideBySide" id="lastSec">
      <div class="colContainer">
        <div class="justifyLeft">
          <h1 class="floatingHeader">Organized. Easier. Better. Faster</h1>
          <h1 class="largeHeader">Share and manage important documents</h1>
          <!-- <br /> -->
          <!-- <h1 class="largeHeader">With Safe Eye</h1> -->
          <h7 class="largeHeaderParagraph">
            <!-- <br />
            <br /> -->
            With a few clicks all safety data sheets, OH&S manual, company
            safety manual are instantly in the pockets of all workers.
          </h7>

          <!-- <button class="seBtn">Schedule Demo</button> -->
        </div>
      </div>
      <div class="colContainer">
        <img
          class="appImage"
          src="@/assets/images/appPics/docImage.webp"
          alt="Safe Eye Logo"
        />
      </div>
    </section>
  </div>
  <bottomFooter id="bottomFooter" />
</template>

<script>
  // import topHeader from "@/components/headersAndFooters/topHeader.vue";
  // import mainHeader from "./components/headersAndFooters/mainHeader.vue";
  // import seLogo from "@/components/logo/seLogo.vue";
  import mainPageVideo from "@/components/mainPageVideo/mainPageVideo.vue";
  // import bottomFooter from "@/components/headersAndFooters/bottomFooter.vue";
  import "@/assets/styles/variables.css";
  // import mainHeader from "./components/headersAndFooters/mainHeader.vue";
  // import { VBtn } from "vuetify";
  // import { VApp } from "vuetify";
  // import { VMenu, VList, VListItem } from "vuetify";
  import { onMounted } from "vue";
  export default {
    name: "homePage",
    components: {
      // topHeader,
      // bottomFooter,
      // mainHeader,
      mainPageVideo,
      // mainHeader,
      // VMenu,
      // VList,
      // VListItem,
    },

    setup() {
      onMounted(() => {
        // console.log("mounted");
        // console.log(this.$refs.mainPageVideo);
        window.scroll({
          // top: 330,
          // left: 100,

          behavior: "smooth",
        });
      });

      return {};
    },
  };
</script>

<style scoped>
  .colContainer {
    padding: 2vw;
  }
  .justifyLeft {
    justify-content: flex-end;
  }
  #app {
    font-family: vf, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  .button {
    background-color: var(--primary-color);
    color: #fff;
    border-radius: var(--border-radius);
    padding: 8px 16px;
    cursor: pointer;
  }
  /*
    #topHeader {
      z-index: 1;
      position: sticky;
      top: -2rem;
      left: 0;
    } */

  #bottomFooter {
    /* position: fixed;
      bottom: 0; */
    /* margin-top: 10rem; */
  }

  /* h1 {
      color: var(--se-light);
      -webkit-text-stroke: 1px #282828;
      text-shadow: 0px 2px 2px #5a5959;
    } */

  section {
    display: flex;
    width: auto;
    /* margin-bottom: 10rem; */
  }

  #mainVideo,
  .appImage {
    /* margin: 1rem; */
    margin-top: 4rem;
    width: auto;
    margin-bottom: 4rem;

    /* height: 100%; */

    /* padding: 10rem; */
    object-fit: fill;
    border-radius: 2.4rem;
    /* border: 1px solid black; */

    /* border: 10px solid rgba(255, 255, 255, 0.6); */
    offset: 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    /* border: -2px solid black; */

    box-shadow: rgba(255, 255, 255, 0) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    /* background-color: red !important; */
  }

  #mainVideo {
    width: inherit;
  }

  .appImage {
    /* margin: 10rem; */
    /* border: 1px solid red;
    z-index: 10; */
    width: auto;

    height: 100%;
    object-fit: scale-down;

    /* padding: 10rem; */

    border-radius: 2.7rem;

    /* border: 10px solid rgba(255, 255, 255, 0.6); */

    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  #spacerSection {
    padding-top: 1vh;
    height: 20vh;
  }

  /* #lastSec {
    margin-bottom: 40rem;
  } */

  #mainPage {
    /* border: 4px solid gray; */
    margin-top: -8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #mainPageVideo {
    /* border: 12px solid red;
      height: 40vh; */

    /* margin-bottom: 20rem; */
  }

  @media only screen and (max-width: 350px) {
    #spacerSection {
      padding-top: 1vh;
    }
    .appImage {
      width: 100%;
      margin: 0;
      padding: 0px;
      border-radius: 5vw;
      box-shadow: none;
    }
  }
  @media only screen and (max-width: 450px) {
    .colContainer {
      align-items: center;
      justify-content: center;
    }
    #spacerSection {
      height: 0vh;
    }

    .appImage {
      width: 100%;
      margin: 0rem;
      border-radius: 5vw;
      box-shadow: none;
    }

    #spacerSection {
      margin-top: 1rem;
    }
    .sideBySide {
      flex-direction: column;
      margin-bottom: 1rem;
    }
    .floatingHeader {
      font-size: 0.5rem;
      margin-top: 2.5vh;
    }

    .largeHeader {
      font-size: 1.25rem;
    }
    .largeHeaderParagraph {
      font-size: 0.5rem;
    }
    .appImage {
      margin: 4rem;
      margin-top: 0rem;
      /* width: 100%; */
      border-radius: 5vw;
      box-shadow: none;
    }
    .colContainer {
      margin-top: 1rem;
      width: 70%;
    }

    #mainVideo {
      z-index: 0;
      /* width: 70rem; */
      margin-top: 0rem;
      border-radius: 7vw;
      width: 150%;
      margin: 0;
      margin-top: 0rem;
      margin-bottom: 4rem;
    }
    #spacerSection {
      padding-top: 1vh;
      height: 40vw;
      margin-top: -37vw;
    }
    .colContainer {
      margin: 0rem;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 450px) {
    .sideBySide {
      flex-direction: column;
      margin-bottom: 0vw;
      margin-top: 0vw;
    }
    #spacerSection {
      margin-top: 0rem;
      height: 10;
    }
    section {
      flex-direction: column;
      /* border: 2px solid green; */
      padding: 0rem;
      width: 100%;
      z-index: 0;
      margin-bottom: 2rem;
      margin-right: 2rem;
    }

    #mainVideo {
      z-index: 0;
      margin-bottom: 80rem;
      width: 100%;
      margin: 0rem;
    }

    .colContainer {
      /* border: 2px solid red; */
      margin: 0rem;
    }
  }
  @media only screen and (max-width: 576px) {
    section {
      width: 100%;
      z-index: 0;
      margin-bottom: 0rem;
      overflow: hidden;
      margin-right: 0rem;
    }
    #mainVideo,
    .appImage {
      margin-top: 4rem;
      width: auto;
      margin-bottom: 4rem;
    }
    #spacerSection {
      margin-top: 0rem;
      /* height: 20vh; */
    }

    #mainVideo {
      margin-top: 4rem;
      width: 100%;
      margin-bottom: 4rem;
    }

    .appImage {
      width: 100%;
      margin: 0rem;
      border-radius: 5vw;
      box-shadow: none;
    }

    .colContainer {
      /* border: 2px solid red; */
      margin: 0rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 2vw;
    }
    .largeHeader {
      font-size: 1.25rem;
    }
    .floatingHeader {
      font-size: 0.375rem;
    }
    .largeHeaderParagraph {
      font-size: 0.375rem;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
    /* .sideBySide {
      flex-direction: column;
    } */

    .appImage {
      width: 100%;
      margin: 0rem;
      border-radius: 5vw;
      box-shadow: none;
    }

    #spacerSection {
      margin-top: 0rem;
    }

    .colContainer {
      margin-top: 0rem;
    }
    #mainVideo {
      width: 100%;
      border-radius: 1vw;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .appImage {
      width: 80%;
      margin: 4rem;
      border-radius: 5vw;
    }
    #mainVideo {
      width: 80%;
      border-radius: 3vw;
    }
    .colContainer {
      margin-top: 0rem;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    #mainVideo {
      width: 70%;
      margin: 2rem;
    }
    #spacerSection {
      margin-top: 0rem;
      height: 50vw;
    }

    .colContainer {
      padding: 0rem;
      margin: 0rem;
    }
    .appImage {
      width: 60%;
      margin: 2rem;
      border-radius: 5vw;
      box-shadow: none;
    }
  }
  /* @media only screen and (min-width: 1200px) and (max-width:) ;{
    #mainVideo {
      width: inherit;
      border-radius: 5vw;
    }
    .colContainer {
      margin-top: 0rem;

      width: 70%;
      border: 2px solid red;
    }
    #spacerSection {
      margin-top: 0rem;
      height: 60vh;
    }
  } */
  @media only screen and (max-width: 1499px) and (min-width: 1201px) {
    #spacerSection {
      margin-top: 0rem;
      height: 50vw;
    }
    #mainVideo {
      width: 70%;
      border-radius: 5vw;
      margin: 1rem;
    }
    .colContainer {
      margin: 0rem;
      margin-top: 0rem;
    }
    .appImage {
      width: 70%;
      margin: 2rem;
      border-radius: 5vw;
      box-shadow: none;
    }
  }
  @media only screen and (min-width: 1500px) {
    #spacerSection {
      margin-top: 0rem;
      height: 40vw;
    }
    #mainVideo {
      /* width: inherit; */
      border-radius: 3vw;
    }

    .appImage {
      width: 50%;
      margin: 2rem;
      border-radius: 4vw;
      box-shadow: none;
    }

    .colContainer {
      margin: 0rem;
    }

    section {
      margin: 0rem;
    }
  }
</style>
