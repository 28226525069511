<template class="page">
  <section>
    <div class="hContainer">
      <div class="start">
        <div class="hContainer">
          <div class="">
            <h3 class="">Support</h3>
            <h2 class="">
              Having difficulty ?<br />
              We've got your back
            </h2>
            <h2></h2>
            <br />
            <p id="mainParagraph">
              Get in touch with our knowledgeable support team and we'll get
              your issue resolved.
            </p>
          </div>
          <div class="card">
            <div class="card">
              <h3>Phone</h3>
              <br />
              <p>Canada & U.S</p>
              <p>Monday to Friday, 8:00am - 5:00pm (Mtn)</p>
              <a class="contactText" href="">
                Phone :
                <a href="tel:4034345865" class="contactInfo"> 403-434-5865 </a>
              </a>
            </div>
            <div class="card">
              <h3>Email</h3>
              <br />
              <p>Canada & U.S</p>
              <p>Monday to Friday, 8:00am - 5:00pm (Mtn)</p>
              <a
                class="contactText"
                href="mailto:support@safe-eye.app"
                id="contactText"
                >Email:
                <a class="contactInfo" href="mailto:support@safe-eye.app">
                  support@safe-eye.app
                </a>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "contactPage",
    setup() {
      return {};
    },
  };
</script>

<style scoped>
  .hContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    /* border: 1px solid red; */
  }

  .page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    /* border: 1px solid red; */
  }

  .end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    line-height: 1 rem;
    padding-top: 0rem;
    padding-left: 0rem;
    /* border: 1px solid red; */
    width: 100%;
    /* text-align: right; */
  }
  .start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1 rem;
    padding-top: 0rem;
    padding-left: 0rem;
    /* border: 1px solid red; */
    width: 100%;

    /* text-align: left; */
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--se-dark);
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  h2,
  h3 {
    padding-bottom: 2.5rem;
  }
  .card {
    padding: 2rem 1rem 2rem 1rem;
    border-radius: 0.5rem;

    /* border: 1px solid red; */

    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .contactInfo {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-color);
    text-decoration: none;
    /* border: 1px solid red; */
  }

  .contactText {
    font-size: 2rem;
    font-weight: 600;
    color: var(--se-light);
    text-decoration: none;
  }

  h2 {
    line-height: 3rem;
  }

  br {
    margin: 2.5rem;
  }

  /* p {
    margin-top: 5vw;
  } */

  @media only screen and (max-width: 350px) {
    #mainParagraph {
      padding-top: 1rem;
      line-height: 2rem;
      border: 2px solid red;
    }
  }
  @media only screen and (max-width: 450px) {
  }
  @media screen and (max-width: 452px) {
    h2 {
      font-size: 2rem;
    }
    #mainParagraph {
      padding-top: 1rem;
      line-height: 2rem;

      margin: 0rem;
    }
  }
  @media only screen and (max-width: 576px) {
  }
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
  }

  @media only screen and (max-width: 1000px) {
    #mainParagraph {
      margin-top: 15vw;
    }
    .hContainer {
      flex-direction: column;
      /* padding-top: 5vw;
      padding-bottom: 5vw;
      padding-left: 5vw;
      padding-right: 5vw; */
      padding: 5vw;
    }

    .card {
      margin-top: 4vw;
    }
  }
  @media only screen and (max-width: 991.98px) {
    #mainParagraph {
      margin-top: 5vw;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  }

  @media only screen and (max-width: 1499px) and (min-width: 1201px) {
  }
  @media only screen and (min-width: 1500px) {
  }
</style>
