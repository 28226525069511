<template>
  <div id="mainContainer">
    <seLogo />
    <div id="rowContainer">
      <a href="/features" class="headerBtn">Features</a>
      <!-- <a class="headerBtn">Pricing</a> -->
      <!-- <a class="headerBtn">Book demo</a> -->
      <a
        href=""
        class="headerBtn"
        id="bookDemoBtn"
        onclick="Calendly.initPopupWidget({url: 'https://calendly.com/safe-eye/free-consultation'});return false;"
        >Schedule demo</a
      >
    </div>
  </div>
</template>

<script>
  //   import { VAppBar, VToolbarTitle, VApp } from "vuetify";
  //   import { VMenu, VList, VListItem } from "vuetify";
  import seLogo from "../logo/seLogo.vue";

  export default {
    name: "mainHeader",

    components: {
      //   VAppBar,
      //   VToolbarTitle,
      //   VApp,
      //   VMenu,
      //   VList,
      //   VListItem,
      seLogo,
    },
  };
</script>

<style scoped>
  #mainContainer {
    /* align-items: center; */

    width: 100vw;
    height: 4rem;
    background-color: rgba(255, 255, 255, 0.25);
  }

  #mainContainer:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .headerBtn {
    color: rgba(255, 255, 255, 0.75);
    font-family: vf;
    /* font-size: 1.5rem; */
    /* font-size: 5vw; */
    font-size: 150%;
    font-weight: 500;
    margin-left: 1rem;
    margin-right: 1rem;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap; /* Add this line */
  }

  .headerBtn:hover {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
  }

  #rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid red; */
    justify-self: flex-end;
    width: auto;
    /* flex-wrap: nowrap; */
    /* border: 1px solid red; */
    margin-right: 1rem;
  }

  /* se-logo {
    justify-self: flex-start;
    position: absolute;
    top: 0;
    left: 0;
  } */

  @media only screen and (max-width: 350px) {
    .headerBtn {
      margin-left: 0rem;
      margin-right: 0.8rem;
    }
  }
  @media only screen and (max-width: 380px) {
    .headerBtn {
      font-size: 80%;
    }
  }
  @media only screen and (max-width: 576px) {
    .headerBtn {
      font-size: 100%;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767.98px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991.98px) {
  }
  @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  }
  @media only screen and (min-width: 1200px) {
  }
  @media only screen and (min-width: 1500px) {
  }
</style>
