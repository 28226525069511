import { createApp } from "vue";
import App from "./App.vue";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdJrVkvex24hH00YsjX1cm4jep3JSmrPM",
  authDomain: "safe-eye-website-61713.firebaseapp.com",
  projectId: "safe-eye-website-61713",
  storageBucket: "safe-eye-website-61713.appspot.com",
  messagingSenderId: "413335974806",
  appId: "1:413335974806:web:546860afdce4c8ec8863b7",
  measurementId: "G-7T3ZD0G0ZS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { logEvent } from "firebase/analytics";

logEvent(analytics, "loaded Site");

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import { createRouter, createWebHistory } from "vue-router";
import * as directives from "vuetify/directives";
import featuresPage from "@/pages/featuresPage.vue";
import homePage from "@/pages/homePage.vue";
import contactPage from "@/pages/contactPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: homePage, name: "homePage" },
    { path: "/features", component: featuresPage, name: "featuresPage" },
    { path: "/contactPage", component: contactPage, name: "contactPage" },
  ],
});

const vuetify = createVuetify({
  components,
  directives,
});

createApp(App).use(vuetify).use(router).mount("#app");
